import React from 'react';
import PropTypes from 'prop-types';

import CarbonImg from '../images/carbon-logo.png';
import Layout from '../components/Layout';

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <div>
      <p>
        <img src={CarbonImg} className="logo" width="200" alt="Carbon" />
      </p>
      <p>
        We are refreshing our documentation to reflect recent product updates. Please check back soon.
      </p>
    </div>
  </Layout>
);

IndexPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default IndexPage;
